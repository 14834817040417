import React from "react";
import PropTypes from "prop-types";
import AuthProvider from "./AuthProvider";
import  DisplayUrl  from './DisplayUrl';
import { DefaultButton } from 'office-ui-fabric-react';


import "./App.css";

//const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

class App extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        adminConsentUrl: PropTypes.string,
        error: PropTypes.string,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        
    };
    
    //<button onClick={this.props.onSignIn}>Sign In</button>
    render() {
        return (<>
            <div class="mainText">
                <section>
                    
                    {!this.props.account ? (
                        <>
                        <h1 class="ms-fontSize-18 extraMargin">
                        yourMAIL für den Office 365 Tenant freigeben
                        </h1>
                        
                        <p class="ms-fontSize-14 extraMargin">Damit Unternehmen die Kontrolle darüber behalten, welche Applikationen in Office 365 genutzt werden
                        können, müssen Applikationen je nach benötigten Rechten, einmalig mit administrativen Rechten freigeschaltet werden (Admin Consent).
                        Danach werden keine administrativen Rechte für den Betrieb benötigt. Jeder Benutzer kann nur im eigenen Benutzerkontext, also mit den
                        eigenen Rechten, auf die für ihn freigeben Inhalte zugreifen.</p>
                        <div class="extraMargin"> 
                            <DefaultButton  onClick={this.props.onSignIn} title="anmelden">Freigabeprozess jetzt starten!</DefaultButton>
                        </div>

                        <p class="ms-fontSize-14 extraMargin">Nach der Anmeldung bei Office 365 wird ein Freigabelink für Ihren Tenant generiert. Wenn Sie 
                        Administrationsrechte für O365 besitzen, kann die Freigabe direkt hier erteilt werden. Falls Sie keine administrative Rechte besitzen,
                        können Sie den Freigabe-Link an Ihren Administrator weiterleiten.</p>
                        </>
                    ) : (
                        <>
                        <div>
                        </div>
                                                </>
                    )}
                    {this.props.error && (
                        <p className="error">Error: {this.props.error}</p>
                    )}
                </section>
                <section className="data">
                    {this.props.adminConsentUrl && (
                        <DisplayUrl adminConsentUrl={this.props.adminConsentUrl}/>
                    )}
                    
                    {/*this.props.account && (
                        <div className="data-account">
                            <h2>Session Account Data</h2>
                            <Json data={this.props.account} />
                        </div>
                    )*/}
                </section>
            </div>
            <div class="orangeInfo"><div class="orangeInfoText">Wichtiger Hinweis: Die yourMAIL GmbH hat zu keinem Zeitpunkt Zugriff auf Ihre Daten. 
            Die oben beschriebenen Rechte, beziehen sich ausschließlich auf den Zugriff des Clients zum Office 365 Tennant.</div></div>
            </>
        );
    }


    
}

export default AuthProvider(App);