import React, { Component } from "react"
import { DefaultButton } from 'office-ui-fabric-react';
import { IconButton, IIconProps, initializeIcons } from 'office-ui-fabric-react';
import { TextField, ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from 'office-ui-fabric-react/lib/Stack';

   // Initialize icons in case this example uses them
   initializeIcons();
 
export default class DisplayUrl extends Component {
  constructor(props) {
    super(props)
 
    this.state = {
      copySuccess: false
    }
  }



  handleClick = () => {
    if (typeof window !== 'undefined')
    {
      window.location.href = this.props.adminConsentUrl;
    }
  }

 
  copyCodeToClipboard = () => {
    const el = document.getElementById("urlField")

    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }
 
  render() {
  
    return (
     <div>
      <div class="extraMargin">
        <h1 class="extraMargin ms-fontSize-18"><b>Freigabe-Link:</b></h1>
         <Stack  horizontal tokens={{  childrenGap: 5,  padding: 0}} > 
          <Stack.Item grow={5}>
              <TextField id="urlField" readOnly defaultValue="" ref={(TextField) => {
                  return this.input = TextField;
                }}   value={this.props.adminConsentUrl}/>
          </Stack.Item>
          <Stack.Item grow={0}>
            <div style={{"textAlign":"left"}}>
              <IconButton iconProps={{ iconName: 'Copy' }} title="Copy to clipboard" ariaLabel="Copy"  onClick={() => this.copyCodeToClipboard()}/>
            </div>
          </Stack.Item>
        </Stack>    
          {
            this.state.copySuccess ?
            <div style={{"color": "green"}}>
              Link in die Zwischenablage übernommen!
            </div> : null
          } 
        </div>
        <div>
        <div class="extraMargin">
        <DefaultButton href={this.props.adminConsentUrl} target="_blank" title="autorisieren">
        Freigabe mit administrativen Rechten erteilen
        </DefaultButton>
        </div>        

        <p class="ms-fontSize-14 extraMargin">Wenn Sie administrative Rechte besitzen, können Sie die Freigabe über den Button erteilen. Falls Sie keine administrativen Rechte besitzen,
        versenden Sie den Link oben bitte an Ihren Administrator. Falls Sie Fragen haben, wenden Sie sich bitte an unseren <a href="https://yourmail.eu/support/">Support</a>.</p>
        
        </div>
        </div>
    )
  }
} 

//<button onClick={() => this.handleClick()}>Jetzt öffnen</button>
/*
<input readOnly
ref={(input) => this.input = input}
value={this.props.adminConsentUrl}
/>
*/