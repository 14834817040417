import React, { Component } from "react";
import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS
} from "./auth-utils";

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
// const useRedirectFlow = true;

export default C =>
    class AuthProvider extends Component {
        constructor(props) {
            super(props);

            this.state = {
                account: null,
                adminConsentUrl: null,
                error: null,
                emailMessages: null,
                graphProfile: null,
                myJoinedTeams: null
            };
        }

        async acquireToken(request, redirect) {
            return msalApp.acquireTokenSilent(request).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msalApp.acquireTokenRedirect(request)
                        : msalApp.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
        }

        async onSignIn(redirect) {
            if (redirect) {
                return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
            }

            const loginResponse = await msalApp
                .loginPopup(GRAPH_REQUESTS.LOGIN)
                .catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

            if (loginResponse) {

            //https://login.microsoftonline.com/e873b50c-0802-45f8-b7bf-11f1e8020056/adminconsent?client_id=3932e83d-62c5-4f03-aaa9-7c6e86dbafad&state=&redirect_uri=https://login.microsoftonline.com/common/oauth2/nativeclient


                const tenentId = loginResponse.account.idTokenClaims.tid;
                const adminConsentUrl = await this.generateAdminConsentUrl(tenentId);

                this.setState({
                    adminConsentUrl,
                    account: loginResponse.account,
                    error: null
                });
                
                /*
                const tokenResponse = await this.acquireToken(
                    GRAPH_REQUESTS.LOGIN
                ).catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

                if (tokenResponse) {
                    const graphProfile = await fetchMsGraph(
                        GRAPH_ENDPOINTS.ME,
                        tokenResponse.accessToken
                    ).catch(() => {
                        this.setState({
                            error: "Unable to fetch Graph profile."
                        });
                    });

                    if (graphProfile) {
                        this.setState({
                            graphProfile
                        });
                    }

                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
                        this.readMail(tokenResponse.accessToken);
                    }

                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.USER_READ_ALL) > 0) {
                        return this.readMyTeams(tokenResponse.accessToken);
                    }

                }
            */
            }
        }

        onSignOut() {
            msalApp.logout();
        }

        async onRequestEmailToken() {
            const tokenResponse = await this.acquireToken(
                GRAPH_REQUESTS.EMAIL,
                useRedirectFlow
            ).catch(e => {
                this.setState({
                    error: "Unable to acquire access token for reading email."
                });
            });

            if (tokenResponse) {
                return this.readMail(tokenResponse.accessToken);
            }
        }


        async queryGraph(accessToken, apiUrl) {
            const result = await fetchMsGraph(apiUrl, 
                accessToken
                ).catch(() => {
                    this.setState({
                        error: "Unable to fetch data."
                    });
                }); 

                if (result) {
                    this.setState({
                        result,
                        error:null
                    });
                }
        }

        async readMail(accessToken) {
            const emailMessages = await fetchMsGraph(
                GRAPH_ENDPOINTS.MAIL,
                accessToken
            ).catch(() => {
                this.setState({
                    error: "Unable to fetch email messages."
                });
            });

            if (emailMessages) {
                this.setState({
                    emailMessages,
                    error: null
                });
            }
        }

        async readMyTeams(accessToken) {
            const myJoinedTeams = await fetchMsGraph(
                GRAPH_ENDPOINTS.ME_JOINED_TEAMS,
                accessToken
            ).catch(() => {
                this.setState({
                    error: "Unable to fetch email messages."
                });
            });

            if (myJoinedTeams) {
                this.setState({
                    myJoinedTeams,
                    error: null
                });
            }
        }

        async generateAdminConsentUrl(tenantId)
        {
            const appId = msalApp.clientId;
            const redirectUrl = "https://yourmail.eu/installationSuccessful/"

            const url = "https://login.microsoftonline.com/" + tenantId + "/adminconsent?client_id="+ appId +"&state=&redirect_uri="+redirectUrl ;

            return url;
        }

        async componentDidMount() {
            msalApp.handleRedirectCallback(error => {
                if (error) {
                    const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                    // setState works as long as navigateToLoginRequestUrl: false
                    this.setState({
                        error: errorMessage
                    });
                }
            });

            const account = msalApp.getAccount();

            this.setState({
                account
            });

            if (account) {
                const tokenResponse = await this.acquireToken(
                    GRAPH_REQUESTS.LOGIN,
                    useRedirectFlow
                );

                const adminConsentUrl = await this.generateAdminConsentUrl(account.tenantId);
                this.setState({adminConsentUrl});


                if (tokenResponse) {
                    const graphProfile = await fetchMsGraph(
                        GRAPH_ENDPOINTS.ME,
                        tokenResponse.accessToken
                    ).catch(() => {
                        this.setState({
                            error: "Unable to fetch Graph profile."
                        });
                    });

                    if (graphProfile) {
                        this.setState({
                            graphProfile
                        });
                    }

                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
                         this.readMail(tokenResponse.accessToken);
                    }

                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.USER_READ_ALL) > 0)
                    {
                        return this.readMyTeams(tokenResponse.accessToken);
                    }
                }
            }
        }

        render() {
            return (
                <C
                    {...this.props}
                    account={this.state.account}
                    adminConsentUrl={this.state.adminConsentUrl}
                    error={this.state.error}
                    graphProfile={this.state.graphProfile}
                    onSignIn={() => this.onSignIn(useRedirectFlow)}
                    onSignOut={() => this.onSignOut()}
                    onRequestEmailToken={() => this.onRequestEmailToken()}
                    onRequestApi={() => this.onRequestApi()}
                />
            );
        }
    };
