import { UserAgentApplication, Logger, LogLevel} from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read",
    USER_READ_ALL: "User.Read.All",
    GROUP_READ_ALL: "Group.Read.All"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages",
    ME_JOINED_TEAMS: "https://graph.microsoft.com/v1.0/me/joinedTeams",
    ME_MEMBER_OF :"https://graph.microsoft.com/v1.0/me/memberOf",
    GROUPS: "https://graph.microsoft.com/v1.0/Groups",
    TEAMS: "https://graph.microsoft.com/v1.0/Teams"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    EMAIL: {
        scopes: [
            GRAPH_SCOPES.MAIL_READ,
            GRAPH_SCOPES.USER_READ_ALL
        ]
    }
};

export const msalApp = new UserAgentApplication({
    auth: {
        //clientId: "245e9392-c666-4d51-8f8a-bfd9e55b2456",
        
        //Development APP-ID
        //clientId: "d3f09802-b297-445d-ba42-0772ec167e85",
        
        //Live APP_ID
        clientId: "3932e83d-62c5-4f03-aaa9-7c6e86dbafad",
        authority: "https://login.microsoftonline.com/common",
        validateAuthority: true,
        //postLogoutRedirectUri: "http://localhost:3000",
        postLogoutRedirectUri: "https://freigabe.yourmail.eu?logout=true",
        //redirectUri: "https://freigabe.yourmail.eu",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 500,
        logger: new Logger((logLevel, message) => {
            console.log(message);
        }, {
            level: LogLevel.Verbose,
            piiLoggingEnabled: true
        }),
        telemetry: {
            applicationName: "react-sample-app",
            applicationVersion: "1.0.0",
            telemetryEmitter: (events) => {
                console.log('Telemetry Events:', events);
            }
        }
    }
});
